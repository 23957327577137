import React,{useEffect, useState} from 'react';
//import React, { Component } from 'react';
//import { BrowserRouter as Router, Route } from 'react-router-dom';
//import Header from './components/layout/Header';
//import About from './components/pages/About';
import Recipe from './Recipe';
import './App.css';
//import { render } from '@testing-library/react';

const App = () => {
  const APP_ID = '0342bb6b';
  const APP_KEY = '1490193de11527b0894d7a70f8361c2a';

  const[recipes, setRecipes] = useState([]);
  const [search, setSearch] = useState("");
  const[query, setQuery] = useState('');
 
  useEffect(() => {
    getRecipes();
    }, [query]);

  const getRecipes = async () =>{
     const response = await fetch (`https://api.edamam.com/search?q=${query}&app_id=${APP_ID}&app_key=${APP_KEY}`); 
     const data = await response.json();
     setRecipes(data.hits);
  };

  const updateSearch = e => {
       setSearch(e.target.value);
  };
  
  const getSearch = e => {
    e.preventDefault();
    setQuery(search);
    setSearch('');
  };
              return (
              <div className="App">
                   <h1 className="headerline">Recipe Api Search</h1>
                   <div className="headerline2">This React app uses the Edamam Recipe Api to fetch recipes based on your keyword.</div>
                   <div className="headerline3">By Jerry Alejandro</div>
                   <div className="headerline3">Enter a keyword and click search.</div>
                  <form onSubmit= {getSearch} className = "search-form">
                    <input className = "search-bar" type="text" value={search} onChange={updateSearch}/>
                       <button className = "search-button" type="submit">Search
                       </button>
                    </form>
                        <div className= "recipes">
                            {recipes.map(recipe =>(
                                <Recipe
                                    key= {recipe.recipe.label}
                                    title={recipe.recipe.label} 
                                    calories={recipe.recipe.calories}
                                    image = {recipe.recipe.image}
                                    ingredients={recipe.recipe.ingredients}
                                  />
                          ))}
                        </div>
                </div>
          );
          
};

export default App;